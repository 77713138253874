<template>
    <div class="flex mx-5 my-5">
        <SideBar :btnText="'+ 新增最新消息'" @clickBtn="addClass" />
        <div class="pb-5 flex-1">
            <div class="shadow-lg bg-white rounded-lg ml-3 pb-5 relative">
                <LoadingComponent :isLoading="loading" />
                <div class="overflow-x-auto">
                    <table class="myTable">
                        <thead>
                            <tr>
                                <th>內容</th>
                                <th>連結</th>
                                <th>使用期間</th>
                                <th v-permission="['update']">編輯</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                                <td class="show-ellipsis">{{ item.text }}</td>
                                <td class="show-ellipsis">
                                    {{ item.url }}
                                </td>
                                <td>
                                    <span v-if="item.start_date || item.end_date">
                                        {{ item.start_date | formatDate }} -
                                        {{ item.end_date | formatDate }}
                                    </span>
                                    <span v-else> 不限使用期間 </span>
                                </td>
                                <td v-permission="['update']" class="cursor-pointer" @click="goEdit(item.id)">
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
import SideBar from "../components/SideBar.vue";
export default {
    name: "LastestNews",
    components: {
        LoadingComponent,
        SideBar,
    },
    data() {
        return {
            datas: [],
            loading: false,
        };
    },
    methods: {
        // 新增
        addClass() {
            this.$router.push({ name: "add_new" });
        },
        // 編輯
        goEdit(id) {
            this.$router.push({
                path: `/homepage_setting/lastest_news/edit/${id}`,
            });
        },
    },
    async mounted() {
        this.loading = true;
        const { status, data } = await this.$api
            .GetNewsList()
            .then((res) => {
                return { status: res.status, data: res.data.news };
            })
            .catch(() => {
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            })
            .finally(() => {
                this.loading = false;
            });
        if (status == 200) {
            this.datas = [...data];
        }
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
    tbody {
        tr .show-ellipsis {
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
